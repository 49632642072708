import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from 'components/Layout';
import useAppInfo from '../hooks/portal/useAppInfo';
// import LoginDialog from '../Accounts/Login';
import { selectName, dispatchLogin } from '../../reducers/Authorization';
import { selectSystem } from '../../reducers/ForecastType';
// import { dispatchWaterToolsPortal, selectIsPortal } from '../../reducers/WaterToolsPortal';
import { dispatchStandAloneApp, dispatchWaterToolsPortal, selectIsPortal } from '../../reducers/WaterToolsPortal';

const MOWebLayout = ({ children }) => {
  const name = useSelector(selectName, shallowEqual);
  const isPortal = useSelector(selectIsPortal, shallowEqual);
  const system = useSelector(selectSystem, shallowEqual);
  const { ghm } = system || {};
  const dispatch = useDispatch();
  const { options, isPortal: isPortalFirsttime, pending, access } = useAppInfo();

  // console.log('name: ', name);
  // console.log('isPortal: ', isPortal);
  // console.log('system: ', system);
  // console.log('access: ', access);
  // console.log('localStorage: ', localStorage.getItem('AccessToken'));

  useEffect(() => {
    if (!pending) {
      if (isPortalFirsttime) {
        dispatch(dispatchWaterToolsPortal(options));
        const userGroups = Array.isArray(access.userGroups) ? access.userGroups.join(',') : access.userGroups;
        dispatch(
          dispatchLogin({
            accessToken: access.token,
            userGroups,
            name: access.name,
          }),
        );
      } else {
        dispatch(dispatchStandAloneApp());
      }
    }
  }, [pending, access, dispatch, isPortalFirsttime, options]);

  return (
    <>
      {/* <LoginDialog /> */}
      {pending ? (
        <CircularProgress
          style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12, zIndex: 999 }}
        />
      ) : (
        <Layout mainAppBar={isPortal} navBar={!ghm} control={<Layout.Login name={name} />}>
          {children}
        </Layout>
      )}
    </>
  );
};

export default MOWebLayout;
