import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import axios from '../containers/hooks/useAxios';

const tokenKey = 'AccessToken';
export const sliceName = 'Authorization';

const initialState = { name: undefined, userGroups: [], expireTime: undefined };

const getStateFromStorge = () => {
  let defaultState = JSON.parse(localStorage.getItem(tokenKey));
  if (defaultState && defaultState.accessToken && new Date(defaultState.expireTime) > new Date()) {
    axios.defaults.headers.common.Authorization = `Bearer ${defaultState.accessToken}`;
  } else {
    defaultState = initialState;
  }
  return defaultState;
};

const slice = createSlice({
  name: sliceName,
  initialState: getStateFromStorge(),
  reducers: {
    dispatchLogin: (state, { payload }) => {
      const { name, userGroups, accessToken, userName } = payload;
      const curDate = new Date();
      let expireTime;
      if (process.env.NODE_ENV === 'production') {
        expireTime = new Date(curDate.setDate(curDate.getDate() + 1));
      } else {
        expireTime = new Date(curDate.setDate(curDate.getDate() + 30));
      }
      const access = {
        name,
        userGroups,
        accessToken,
        expireTime,
        userName,
      };
      localStorage.setItem(tokenKey, JSON.stringify(access)); // share
      axios.defaults.headers.common.Authorization = `Bearer ${access.accessToken}`;
      state.name = access.name;
      state.userName = access.userName;
      state.userGroups = access.userGroups;
      state.expireTime = access.expireTime;
    },
    dispatchLogout: () => {
      localStorage.setItem(tokenKey, JSON.stringify({}));
      axios.defaults.headers.common.Authorization = undefined;
      return initialState;
    },
  },
});

export default slice.reducer;
export const { dispatchLogin, dispatchLogout } = slice.actions;
export const selectName = createSelector(
  (state) => state[sliceName],
  (state) => {
    if (new Date(state.expireTime) > new Date()) {
      return state.name;
    }
    return undefined;
  },
);
export const selectUserName = createSelector(
  (state) => state[sliceName],
  (state) => {
    if (new Date(state.expireTime) > new Date()) {
      return state.userName;
    }
    return undefined;
  },
);
export const selectUserId = createSelector(
  (state) => state[sliceName],
  (state) => {
    if (new Date(state.expireTime) > new Date()) {
      return state.accessToken;
    }
    return undefined;
  },
);
export const selectUserGroups = createSelector(
  (state) => state[sliceName],
  (state) => {
    if (new Date(state.expireTime) > new Date()) {
      return state.userGroups;
    }
    return undefined;
  },
);
export const selectIsAdmin = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups && (userGroups.indexOf('Website Admin') !== -1 || userGroups.indexOf('Administrator') !== -1),
);
export const selectIsAllScenarios = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('AllScenarios') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsLastScenario = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('LastScenario') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsScenario = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('Scenario') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsDashboard = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('Dashboard') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsDashboardEdit = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('DashboardEdit') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsScenarioEdit = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('ScenarioEdit') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsConfiguration = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('Configuration') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsStatusboard = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('StatusBoard') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsStatusboardJobStatus = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('JobStatus') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsStatusboardTables = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('Tables') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsStatusboardFeeds = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('Feeds') !== -1 ||
      userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Administrator') !== -1),
);
export const selectIsAdminOrUser = createSelector(
  selectUserGroups,
  (userGroups) =>
    userGroups &&
    (userGroups.indexOf('Website Admin') !== -1 ||
      userGroups.indexOf('Users') !== -1 ||
      userGroups.indexOf('Editors') !== -1),
);
