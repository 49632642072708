import axios from '../containers/hooks/useAxios';

const path = 'api/timeseries/';

export const getTimeSeries = async (forecastType, system, id) => {
  const response = await axios.get(
    `${path + system.dbName}-timeseries/${id
      .split('|')
      .join('||')
      .split('/')
      .join('|')
      .replace('[Path]', forecastType.simulationPath)}`,
  );
  const { data } = response;
  return data;
};

export const getInfos = async (forecastType, system, ids) => {
  const infos = ids.map((x) =>
    axios.get(
      `${path + system.dbName}-timeseries/${x.path
        .split('|')
        .join('||')
        .split('/')
        .join('|')
        .replace('[Path]', forecastType.simulationPath)}`,
      { res: 'info', name: x.name },
    ),
  );

  const max = ids
    .filter((x) => x.exceedence)
    .map((x) =>
      axios.get(
        `${path + system.dbName}-timeseries/${x.path
          .split('|')
          .join('||')
          .split('/')
          .join('|')
          .replace('[Path]', forecastType.simulationPath)}/values`,
        { res: 'exceedence', name: x.name, tValue: x.tValue },
      ),
    );
  const list = infos.concat(max);
  return Promise.all(list)
    .then((responses) =>
      responses.map((x) => {
        let d = x.data;
        if (x.config.res === 'exceedence') {
          const temp = x.data.map((f) => f[1]);
          // eslint-disable-next-line prefer-destructuring
          d = x.data[temp.indexOf(Math.max.apply(null, temp))][0];
        }
        return { data: d, method: x.config.res, name: x.config.name };
      }),
    )
    .catch(() => {
      // react on errors.
    });
};

// TODO implement from and to for observations
export const getTimeSeriesList = async (forecast, timeseries) => {
  const response = await axios.post(
    `${path + forecast.system.dbName}-timeseries/list/values`,
    timeseries.map((id) =>
      id.replace('[Path]', forecast.forecastType.simulationPath.split('|').join('/')).split('|').join('||'),
    ),
  );
  const { data } = response || {};
  return data;
};

export const getTimeSeriesResolver = async ({ timeseries, dbName, folderPath, simulationPath, from, to }) => {
  // should it be simulationStart or TimeOfForecast ask JALU
  let replacer = simulationPath;
  if (replacer === undefined) {
    replacer = folderPath;
  }
  const ts = timeseries.map((id) => id.replace('[Path]', replacer.split('|').join('/')).split('|').join('||'));
  let query = '';
  if (from && to) {
    query = `?from=${from}&to=${to}`;
  }
  const response = await axios.post(`api/${dbName}-timeseries/ts/list/values${query}`, ts);
  const { data } = response || {};
  return data;
};
