import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editingForecastType: null,
  compareForecastType: null,
  editingTimeseries: [],
  wizardHidden: false,
  diffPlaces: [],
  notifications: undefined,
};
export const sliceName = 'Scenario';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    dispatchScenarioReset: () => {
      return initialState;
    },
    dispatchWizardHidden: (state, { payload }) => {
      state.wizardHidden = payload;
    },
    dispatchScenarioAddTimeseries: (state, { payload }) => {
      if (!state.editingTimeseries.some(({ timeseriesId }) => timeseriesId === payload.timeseriesId)) {
        state.editingTimeseries.push(payload);
      }
    },
    dispatchScenarioDiff: (state, { payload }) => {
      if (payload) {
        const { changes = [] } = payload;
        state.diffPlaces = changes;
      } else {
        state.diffPlaces = [];
      }
    },
    dispatchScenarioRemoveTimeseries: (state, { payload }) => {
      state.editingTimeseries = state.editingTimeseries.filter(
        ({ timeseriesId }) => timeseriesId !== payload.timeseriesId,
      );
    },
    dispatchScenarioClearTimeseries: (state) => {
      state.editingTimeseries = [];
    },
    dispatchEditingForecastType: (state, { payload }) => {
      state.editingForecastType = payload;
    },
    dispatchScenarioNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
    dispatchCompareForecastType: (state, { payload }) => {
      state.compareForecastType = payload;
    },
  },
});

export default slice.reducer;
export const {
  dispatchScenarioReset,
  dispatchWizardHidden,
  dispatchScenarioAddTimeseries,
  dispatchScenarioRemoveTimeseries,
  dispatchScenarioClearTimeseries,
  dispatchScenarioDiff,
  dispatchEditingForecastType,
  dispatchScenarioNotifications,
  dispatchCompareForecastType,
} = slice.actions;

export const selectWizardHidden = (state) => state[sliceName].wizardHidden;
export const selectEditingTimeseries = (state) => state[sliceName].editingTimeseries;
export const selectNotifications = (state) => state[sliceName].notifications;
export const selectEditingForecastType = (state) => state[sliceName].editingForecastType;
export const selectDiffPlaces = (state) => state[sliceName].diffPlaces;
export const selectCompareForecastType = (state) => state[sliceName].compareForecastType;
