import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkHelper, createSelectorHelper, createAsyncReducerHelper } from './helper';

export const sliceName = 'Configuration';
const keyIndicator = 'Indicator';
const keyCategory = 'Category';
const keyApplicationLogo = 'applicationLogo';
const initialState = {
  [keyIndicator]: null,
  [keyCategory]: null,
  [keyApplicationLogo]: null,
};
export const dispatchIndicator = createAsyncThunkHelper(`${sliceName}.dispatchIndicator`);
export const dispatchCategory = createAsyncThunkHelper(`${sliceName}.dispatchCategory`);

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setApplicationLogo: (state, action) => {
      state[keyApplicationLogo] = action.payload;
    },
  },
  extraReducers: {
    [dispatchIndicator.fulfilled]: createAsyncReducerHelper((state, { payload }) => {
      state[keyIndicator] = payload;
    }),
    [dispatchCategory.fulfilled]: createAsyncReducerHelper((state, { payload }) => {
      state[keyCategory] = payload;
    }),
  },
});

export const { setApplicationLogo } = slice.actions;
export default slice.reducer;

export const selectCategory = createSelectorHelper(sliceName, (state) => {
  if (state) {
    if (state[keyCategory] !== undefined) {
      return state[keyCategory];
    }
  }
  return {};
});
export const selectIndicator = createSelectorHelper(sliceName, (state) => {
  if (state) {
    if (state[keyIndicator] !== undefined) {
      return state[keyIndicator];
    }
  }
  return [];
});
export const selectApplicationLogo = (state) => state[sliceName][keyApplicationLogo];
