import axios from '../containers/hooks/useAxios';

const path = 'api/systems';

export const getSystems = async () => {
  const response = await axios.get(`${path}/map`);
  const { data } = response || {};
  return data;
};

export const getSystemsConfig = async () => {
  const response = await axios.get(`${path}/config`);
  const { data } = response || {};
  return data;
};

export const getStatus = async () => {
  const response = await axios.get(`${path}/statusCached`);
  const { data } = response || {};
  return data;
};
