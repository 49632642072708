import { createSlice } from '@reduxjs/toolkit';

export const sliceName = 'MapContent';
export const keySingleTS = 'Select TS, Create new plot';
export const keyAddTSplot = 'Select TS, Add to plot';
export const keyInstantAllTS = 'Instant plot of all TS in station';
export const keyAddMore = 'Multi selection by rectangle';
export const tsPlotOptions = [keySingleTS, keyAddTSplot, keyInstantAllTS, keyAddMore];

const initialState = {
  settings: [],
  tableData: {},
  systems: [],
  editObject: undefined,
  tsPlotMode: undefined,
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    dispatchMapContentReset: () => {
      return initialState;
    },
    // DispatchMapContentSystems
    dispatchMapContentSystems: (state, { payload }) => {
      return { ...state, systems: payload };
    },
    // DispatchMapContent
    dispatchMapContent: (state, { payload }) => {
      return { ...state, settings: payload };
    },
    // DispatchAddMapContent
    dispatchAddMapContent: (state, { payload }) => {
      return {
        ...state,
        settings: state.settings.filter((x) => x.category === 'System').concat(payload),
      };
    },
    // DispatchMapContentEditObject
    dispatchMapContentEditObject: (state, { payload }) => {
      return { ...state, editObject: payload };
    },
    dispathcMapContentHoverObject: (state, { payload }) => {
      return { ...state, hoverObject: payload };
    },
    // DispatchMapContentSelections
    dispatchMapContentSelections: (state, { payload }) => {
      state.tableData = { ...state.tableData, ...payload };
      state.settings = state.settings.map((x) => {
        if (payload[x.category]) {
          x.layers = x.layers.map((feature) => {
            if (payload[x.category][feature.name]) {
              const { name, fullname, ...rest } = payload[x.category][feature.name];
              return { ...feature, indicators: rest };
            }
            return feature;
          });
        }
        return x;
      });
      return state;
    },
    dispatchPlotMode: (state, { payload }) => {
      state.tsPlotMode = payload;
    },
  },
});

export default slice.reducer;
export const {
  dispatchMapContentReset,
  dispatchMapContentSystems,
  dispatchMapContent,
  dispatchAddMapContent,
  dispatchMapContentEditObject,
  dispatchMapContentSelections,
  dispathcMapContentHoverObject,
  dispatchPlotMode,
} = slice.actions;
export const selectSettings = (state) => state[sliceName].settings;
export const selectTableData = (state) => state[sliceName].tableData;
export const selectSystems = (state) => state[sliceName].systems;
export const selectEditObject = (state) => state[sliceName].editObject;
export const selectHoverObject = (state) => state[sliceName].hoverObject;
export const selectTSPlotMode = (state) => state[sliceName].tsPlotMode ?? keySingleTS;
