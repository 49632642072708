import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSystemsConfig } from '../factories/SystemApi';
import { dispatchSystem } from './ForecastType';

const initialState = { systems: [], projectName: '', pending: true, error: undefined, applicationTitle: undefined };
export const sliceName = 'Systems';

export const dispatchSystems = createAsyncThunk(`${sliceName}.dispatchSystems`, async (payload, thunkApi) => {
  const { dispatch } = thunkApi;
  const res = await getSystemsConfig();
  res.operationalForecast = res.operationalForecast.map((x) => {
    x.dispatchSystem = () => dispatch(dispatchSystem(x));
    return x;
  });
  return res;
});

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // SET_APP_TITLE
    dispatchSETAPPTITLE: (state, { payload }) => {
      return {
        ...state,
        applicationTitle: payload,
      };
    },
    // DispatchSystemsStatus
    dispatchSystemsStatus: (state, { payload }) => {
      return {
        ...state,
        pending: false,
        systems: state.systems.map((x) => {
          if (payload[x.dbName]) {
            return { ...x, color: payload[x.dbName] };
          }
          return x;
        }),
      };
    },
  },
  extraReducers: {
    [dispatchSystems.pending]: (state) => {
      return { ...state, pending: true };
    },
    [dispatchSystems.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        pending: false,
        applicationTitle: payload.applicationTitle,
        systems: payload.operationalForecast,
      };
    },
  },
});

export default slice.reducer;
export const { dispatchSETAPPTITLE, dispatchSystemsStatus } = slice.actions;
export const selectSystems = (state) => state[sliceName].systems;
export const selectApplicationTitle = (state) => state[sliceName].applicationTitle;
