import axios from '../containers/hooks/useAxios';
import { getTimeSeriesList } from './TimeseriesFactory';

const path = 'api/scenario/';

export const getScenario = async (dbname) => {
  const response = await axios.get(path + dbname);
  const { data } = response || {};
  return data;
};

const editTime = (dbName, time) => {
  if (dbName === 'cloud' && time) {
    return new Date(`${time}Z`).toISOString();
  }
  return time;
};

export const getForecasttypeLayers = async (dbname, scenario) => {
  const response = await axios.get(`${path + dbname}/forecast-layers?scenario=${scenario}`);
  const { data } = response || {};
  return data;
};

export const getTimeseries = async (forecast, timeseries) => {
  const { latest } = forecast.forecastType;

  if (latest.time === forecast.forecastType.time) {
    const response = await axios.post(`${path + forecast.system.dbName}/forecast-latest`, {
      scenario: forecast.forecastType,
      timeseries,
    });
    const { data } = response || {};
    return data;
  }
  return getTimeSeriesList(forecast, timeseries);
};

export const getSimulationLatest = async (dbname, forecastType, ghm) => {
  const { scenarioId } = forecastType;
  const requestHeaders = {
    headers: {
      'api-version': ghm ? 2 : 1,
    },
  };

  const response = await axios.post(
    `${path + dbname}/forecast-latest-time`,
    {
      system: dbname,
      path: forecastType.path,
      scenario: scenarioId,
    },
    requestHeaders,
  );
  const { data } = response || {};
  const {
    cloudFolder2DDataId: projectId,
    simulationEnd,
    simulationPath,
    simulationStart,
    time,
    timeOfForecast,
  } = data || {};

  const newTime = editTime(dbname, time);
  const timeForecast = editTime(dbname, timeOfForecast || time);
  return {
    projectId,
    simulationEnd,
    simulationPath,
    simulationStart,
    time: newTime,
    timeOfForecast: timeForecast,
  };
};

export const getStylelayers = async (dbname, featureCollection) => {
  const response = await axios.get(`${path + dbname}/stylelayers/${featureCollection.split('/').join('|')}`);
  const { data } = response || {};
  return data;
};
export const getStaticLayers = async (dbname) => {
  const response = await axios.get(`${path + dbname}/static-layers`);
  const { data } = response || {};
  return data;
};

export const updateScenario = async (dbname, forecastTypesDto) => {
  const response = await axios.post(`${path + dbname}/create-or-update`, forecastTypesDto);
  const { data } = response || {};
  return data;
};
