import { createSlice } from '@reduxjs/toolkit';
import bbox from '@turf/bbox';
import { WebMercatorViewport, LinearInterpolator } from 'react-map-gl';

export const sliceName = 'MapView';
const keyShowTitle = 'showTitle';

const transition = {
  transitionDuration: 1000,
  transitionInterpolator: new LinearInterpolator(),
};

const initialState = {
  viewport: {},
  showTitle: false,
  mapStyle: process.env.REACT_APP_MAPBOX_STYLE,
  mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_KEY,
  maps: [
    {
      name: 'Vector map',
      mapStyle: process.env.REACT_APP_MAPBOX_STYLE,
      mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_KEY,
    },
    {
      name: 'Bright vector map',
      mapStyle: 'mapbox://styles/luca29/ckkwpc9w34thw17og2cjc56g8',
      mapboxApiAccessToken: 'pk.eyJ1IjoibHVjYTI5IiwiYSI6ImNqcGJheXdjbTBkNXkzeHBoNnhiaGllYXQifQ.Z_1j5gNQ5pwjGFAe3Ovk8w',
    },
    {
      name: 'Monochrome map',
      mapStyle: 'mapbox://styles/luca29/ckksfnd0b0zlw18qyx321kg3u',
      mapboxApiAccessToken: 'pk.eyJ1IjoibHVjYTI5IiwiYSI6ImNqcGJheXdjbTBkNXkzeHBoNnhiaGllYXQifQ.Z_1j5gNQ5pwjGFAe3Ovk8w',
    },
    {
      name: 'Monochrome map - water highlighted',
      mapStyle: 'mapbox://styles/luca29/ckksfq1de13bm17o5ltfv9ck8',
      mapboxApiAccessToken: 'pk.eyJ1IjoibHVjYTI5IiwiYSI6ImNqcGJheXdjbTBkNXkzeHBoNnhiaGllYXQifQ.Z_1j5gNQ5pwjGFAe3Ovk8w',
    },
  ],
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    dispatchViewport: (state, { payload }) => {
      state.viewport = { ...state.viewport, ...payload, bearing: 0 };
    },
    dispatchTransitionViewport: (state, { payload }) => {
      state.viewport = { ...state.viewport, ...payload, bearing: 0, ...transition };
    },
    dispatchMap: (state, { payload }) => {
      state.mapStyle = payload?.mapStyle;
      state.mapboxApiAccessToken = payload?.mapboxApiAccessToken;
    },
    dispatchRecalcViewport: (state, { payload }) => {
      if (payload && Object.keys(state?.viewport).length > Object.keys(initialState?.viewport).length) {
        const box = bbox(payload);
        const dataBounds = [
          [box[0], box[1]],
          [box[2], box[3]],
        ];
        const { longitude, latitude, zoom } = new WebMercatorViewport(state?.viewport).fitBounds(dataBounds, {
          padding: 50,
        });
        state.viewport = { ...state.viewport, longitude, latitude, zoom, ...transition };
        state.initialZoom = state?.viewport?.zoom;
      }
    },
    dispatchShowTitle: (state, { payload }) => {
      state[keyShowTitle] = payload;
    },
  },
});

export default slice.reducer;
export const { dispatchViewport, dispatchRecalcViewport, dispatchTransitionViewport, dispatchMap, dispatchShowTitle } =
  slice.actions;
export const selectViewport = (state) => state[sliceName].viewport;
export const selectMapStyle = (state) => state[sliceName].mapStyle;
export const selectMapboxApiAccessToken = (state) => state[sliceName].mapboxApiAccessToken;
export const selectMaps = (state) => state[sliceName].maps;
export const selectShowTitle = (state) => state[sliceName][keyShowTitle];
