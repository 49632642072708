// import React from 'react';
import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Login } from '@dhi/react-components';

import { dispatchLogin, selectName } from '../../reducers/Authorization';
// import { dispatchLogin } from '../../reducers/Authorization';
// import { resetApp } from '../../actions';
import { selectIsPortal } from '../../reducers/WaterToolsPortal';
import axios from '../hooks/useAxios';

export default ({ loginDialog, setLoginDialog }) => {
  const name = useSelector(selectName, shallowEqual);
  const [currentUser, setCurrentUser] = useState(name);
  const isPortal = useSelector(selectIsPortal, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isPortal && !name) {
      const fetchData = async () => {
        const result = await axios('/api/account/token');
        dispatch(
          dispatchLogin({
            accessToken: result.data.accessToken.token,
            userGroups: '',
            name: '',
            userName: '',
          }),
        );
      };
      fetchData();
    }
  }, [isPortal, name, dispatch]);

  useEffect(() => {
    if (name !== currentUser) {
      window.location.reload();
    }
  }, [name, currentUser]);

  const handleSucess = (user, token) => {
    dispatch(
      dispatchLogin({
        accessToken: token.accessToken.token,
        userGroups: user.userGroups,
        name: user.name,
        userName: user.id,
      }),
    );
    setCurrentUser(user.name);
    setLoginDialog(false);
  };

  const handleLoginClose = () => {
    setLoginDialog(false);
  };

  return (
    // <Dialog open={!isPortal && !name}>
    <Dialog open={loginDialog} onClose={handleLoginClose}>
      <DialogTitle>Login</DialogTitle>
      <div style={{ padding: 10 }}>
        <Login host={axios.defaults.baseURL} onSuccess={handleSucess} />
      </div>
    </Dialog>
  );
};
