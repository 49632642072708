import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { selectForecastType, selectSystem } from './ForecastType';

export const createAsyncThunkHelper = (typePrefix, payloadCreator) => {
  return createAsyncThunk(typePrefix, async (payload, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();
    const { scenarioId } = selectForecastType(state) || {};
    const { systemId } = selectSystem(state) || {};
    let rt;
    if (payloadCreator !== undefined) {
      rt = await payloadCreator({ systemId, scenarioId, value: payload }, thunkApi);
    } else {
      rt = { systemId, scenarioId, value: payload };
    }
    return rt;
  });
};

export const createSelectorHelper = (sliceName, combiner) => {
  const s = createSelector(
    (state) => state[sliceName],
    selectSystem,
    selectForecastType,
    (state, { systemId } = {}, { scenarioId } = {}) => {
      if (state[systemId]) {
        return state[systemId][scenarioId];
      }
      return undefined;
    },
  );
  return createSelector(s, combiner);
};

export const createAsyncReducerHelper = (func) => (state, action) => {
  const { payload } = action;
  const { systemId, scenarioId, value } = payload;
  if (state[systemId] === undefined) {
    state[systemId] = {};
  }
  if (state[systemId][scenarioId] === undefined) {
    state[systemId][scenarioId] = {};
  }
  return func(state[systemId][scenarioId], { ...action, payload: value });
};
