import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { format } from 'date-fns';
// import { createAsyncThunkHelper, createSelectorHelper, createAsyncReducerHelper } from './helper';

interface AnimationState {
  selection: boolean;
  timeStep: number;
  startTime: string;
  endTime: string;
  value: string;
  times2D: string[];
  // items: string[];
}

const initialState: AnimationState = {
  selection: false,
  times2D: [],
  startTime: '',
  endTime: '',
  value: '',
  timeStep: 2,
  // items: [],
};

export const sliceName = 'Animation';
// const keyItems = 'items';
// export const dispatchToggleItems = createAsyncThunkHelper(`${sliceName}.dispatchToggleItems`);

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    dispatchToggleAnimation: (state: AnimationState) => {
      state.selection = !state.selection;
    },
    dispatchValue: (state: AnimationState, { payload }) => {
      state.value = payload;
    },
    dispatchStartTime: (state: AnimationState, { payload }) => {
      state.startTime = payload;
    },
    dispatchEndTime: (state: AnimationState, { payload }) => {
      state.endTime = payload;
    },
    dispatchTimes2D: (state: AnimationState, { payload }) => {
      state.times2D = payload;
    },
    dispatchTimestep: (state: AnimationState, { payload }) => {
      state.timeStep = Number.parseInt(payload, 10);
    },
  },
  // extraReducers: {
  //   [dispatchToggleItems.fulfilled]: createAsyncReducerHelper((state, { payload }) => {
  //     if (!Array.isArray(state[keyItems])) {
  //       state[keyItems] = [];
  //     }
  //     if (state[keyItems].indexOf(payload) !== -1) {
  //       state[keyItems] = state[keyItems].filter((r) => r !== payload);
  //     } else {
  //       state[keyItems] = [...state[keyItems], payload];
  //     }
  //   }),
  // },
});

export default slice.reducer;
export const {
  dispatchToggleAnimation,
  dispatchTimestep,
  dispatchValue,
  dispatchStartTime,
  dispatchEndTime,
  dispatchTimes2D,
} = slice.actions;
export const selectSelection = (state) => state[sliceName].selection;
export const selectTimestep = (state) => state[sliceName].timeStep ?? 0;
export const selectStartTime = (state) => state[sliceName].startTime;
export const selectEndTime = (state) => state[sliceName].endTime;
const selectTimes2D = (state) => state[sliceName].times2D;
export const selectRowValue = (state) => state[sliceName].value;
export const selectValue = createSelector(selectSelection, selectRowValue, (o1, o2) => (o1 && o2) || undefined);

function addHours(i: Date, t: any) {
  const year = i.getFullYear();
  const month = i.getMonth();
  const day = i.getDate();
  const hour = i.getHours() + Number.parseInt(t, 10);
  const min = i.getMinutes();
  const sec = i.getSeconds();
  return new Date(year, month, day, hour, min, sec);
}
export const selectTimes = createSelector(
  selectStartTime,
  selectEndTime,
  selectTimestep,
  selectTimes2D,
  (st, et, t, d) => {
    const array: Date[] = [];
    if (d) {
      array.push(...d.map((r) => new Date(r)));
    }
    if (st && et) {
      if (t > 0) {
        for (let i = new Date(st); i >= new Date(st) && i <= new Date(et); i = addHours(i, t)) {
          array.push(i);
        }
      }
      // else {
      //   array.push(new Date(st));
      // }
    }
    array.sort((a, b) => {
      return a.getTime() - b.getTime();
    });
    return Array.from(new Set<Date>(array)).map((r) => `${format(r, 'yyyy-MM-dd')}T${format(r, 'HH:mm:ss')}`);
  },
);
// export const selectItems = createSelectorHelper(sliceName, (state) => {
//   if (state) {
//     if (state[keyItems] !== undefined) {
//       return state[keyItems];
//     }
//   }
//   return [];
// });
