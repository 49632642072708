import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import LoginIcon from '@material-ui/icons/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import { getVersion } from 'factories/ConfigApi';
// import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoginDialog from '../../../../containers/Accounts/Login';
// import { selectIsAdmin, dispatchLogout } from '../../../../reducers/Authorization';
import { selectIsAdmin } from '../../../../reducers/Authorization';
// import { resetApp } from '../../../../actions';
import aboutImage from '../../../../images/appInfoBackground.png';
import axios from '../../../../containers/hooks/useAxios';

const styles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    transition: 'background ease-in-out 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.mediumGrey.main,
    },
  },
  background: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  aboutBackground: {
    width: '100%',
  },
  aboutTextContainer: {
    position: 'absolute',
    top: '80px',
    left: '40px',
    display: 'flex',
    flexDirection: 'column',
  },
  aboutText: {
    marginLeft: '40px',
  },
  lngOption: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
}));

const Login = ({ name }) => {
  // const dispatch = useDispatch();
  const { push } = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [version, setVersion] = useState('');
  const classes = styles();
  const isAdmin = useSelector(selectIsAdmin, shallowEqual);
  const [aboutDialog, setAboutDialog] = React.useState(false);
  const [firstUser, setFirstUser] = React.useState(false);
  const [loginDialog, setLoginDialog] = React.useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getVersion().then((sourceData) => {
      setVersion(sourceData);
      localStorage.setItem('MOW-Version', sourceData);
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')) {
      i18n.changeLanguage(localStorage.getItem('i18nextLng'));
    } else {
      localStorage.setItem('i18nextLng', 'en');
    }
  }, [i18n]);

  useEffect(() => {
    axios.get('/api/account/firstuser').then((response) => {
      setFirstUser(response.data);
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = React.useCallback(() => {
    handleClose();
    // dispatch(dispatchLogout());
    // resetApp();
    push('/sign-out');
  }, [push]);

  const handleLogin = React.useCallback(() => {
    setLoginDialog(true);
    handleClose();
  }, []);

  const handleAbout = () => {
    setAboutDialog(true);
    setAnchorEl(null);
  };

  const handleAboutClose = () => {
    setAboutDialog(false);
  };

  const handleFirstUSerClose = () => {
    setFirstUser(false);
  };

  const handleChangeLanguage = (e) => {
    localStorage.setItem('i18nextLng', e.currentTarget.value);
    i18n.changeLanguage(e.currentTarget.value);
    setAnchorEl(null);
  };

  const handleUserManagement = React.useCallback(() => {
    handleClose();
    push('/accounts');
  }, [push]);

  const handleGroupCreation = React.useCallback(() => {
    handleClose();
    push('/creategroups');
  }, [push]);

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    password: '',
    repeatPassword: '',
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { password, repeatPassword } = formData;

    if (password !== repeatPassword) {
      setErrors({
        repeatPassword: t('FormPasswordRepeat.data'),
      });
    } else {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/;
      if (!passwordRegex.test(password)) {
        setErrors({
          password: t('FormPasswordRules.data'),
        });
      } else {
        const { repeatPassword: repeatPasswordVal, ...firstUserData } = formData;
        axios
          .post('/api/account/webadmin', firstUserData)
          .then(() => {
            handleFirstUSerClose();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  const isLogged = name || false;
  return (
    <>
      <Box>
        <LoginDialog loginDialog={loginDialog} setLoginDialog={setLoginDialog} />
      </Box>
      <Box display="flex" alignItems="center" height="100%">
        <Divider orientation="vertical" flexItem />

        <Box paddingX="1rem" className={classes.container} onClick={handleClick}>
          <Avatar className={classes.background}>{isLogged ? name.charAt(0) : <LoginIcon fontSize="small" />}</Avatar> :
        </Box>
      </Box>

      <Menu id="avatar-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem>
          <Select
            native
            className={classes.lngOption}
            value={localStorage.getItem('i18nextLng')}
            onChange={handleChangeLanguage}
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="de">German</option>
          </Select>
        </MenuItem>
        {isAdmin && <MenuItem onClick={handleUserManagement}>{t('User Management.data')}</MenuItem>}
        {isAdmin && <MenuItem onClick={handleGroupCreation}>{t('GroupCreation.data')}</MenuItem>}
        <MenuItem onClick={handleAbout}>{t('About.data')}</MenuItem>
        {isLogged ? (
          <MenuItem onClick={handleLogout}>{t('Logout.data')}</MenuItem>
        ) : (
          <MenuItem onClick={handleLogin}>{t('Login.data')}</MenuItem>
        )}
      </Menu>
      <Dialog
        open={firstUser}
        keepMounted
        maxWidth="md"
        backdrop="static"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <img src={aboutImage} className={classes.aboutBackground} alt="" />
          <Box className={classes.aboutTextContainer} alignItems="center">
            <DialogContentText className={classes.aboutText}>
              <Typography variant="caption" style={{ fontSize: '24px', marginRight: '20px' }}>
                {t('WelcomeTitle.data')}
              </Typography>
              <Typography variant="caption">Version {version}</Typography>
              <br />
              <Typography variant="caption" style={{ fontSize: '16px' }}>
                {t('WelcomeLabel.data')}.
              </Typography>
              <br />
              <br />
            </DialogContentText>
            <form onSubmit={handleSubmit} style={{ marginLeft: '-40px' }}>
              <FormControl style={{ marginRight: '20px' }}>
                <InputLabel htmlFor="userName">ID</InputLabel>
                <Input
                  id="userName"
                  name="id"
                  type="text"
                  value={formData.id}
                  required
                  onChange={handleInputChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <FormControl>
                <InputLabel htmlFor="realName">{t('Name.data')}</InputLabel>
                <Input
                  style={{ width: '400px' }}
                  id="realName"
                  name="name"
                  type="text"
                  required
                  value={formData.name}
                  onChange={handleInputChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <br />
              <br />
              <FormControl style={{ marginRight: '20px' }}>
                <InputLabel htmlFor="password">{t('Password.data')}Password</InputLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={formData.password}
                  onChange={handleInputChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                />
                {errors.password && (
                  <FormHelperText style={{ color: 'red' }} className="text-danger">
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
              {/* <br />
                <br /> */}
              <FormControl>
                <InputLabel htmlFor="repeatPassword">{t('RepeatPassword.data')}</InputLabel>
                <Input
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  required
                  value={formData.repeatPassword}
                  onChange={handleInputChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                />
                {errors.repeatPassword && (
                  <FormHelperText style={{ color: 'red' }} className="text-danger">
                    {errors.repeatPassword}
                  </FormHelperText>
                )}
              </FormControl>
              <br />
              <br />
              <br />
              <Button type="submit" variant="outlined" color="primary">
                {t('Create.data')}
              </Button>
              <br />
              <br />
              <br />
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={aboutDialog}
        keepMounted
        onClose={handleAboutClose}
        maxWidth="md"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{t('About.data')}</DialogTitle>
        <DialogContent>
          <img src={aboutImage} className={classes.aboutBackground} alt="" />
          <Box className={classes.aboutTextContainer} alignItems="center">
            <DialogContentText className={classes.aboutText} style={{ marginTop: '190px' }}>
              <Typography variant="caption" style={{ fontSize: '24px' }}>
                MIKE OPERATIONS Web
              </Typography>
              <br />
              <Typography variant="caption">Version {version}</Typography>
            </DialogContentText>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Login;
