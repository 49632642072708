import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@material-ui/core/Box';
import SnackContext from './SnackContext';
import Alert from './Alert';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    bottom: 0,
    zIndex: 9999,
    padding: 5,
  },
}));

const SnackProvider = ({ children }) => {
  const [messages, setMessages] = React.useState([]);
  const classes = useStyles();

  const sendMessage = React.useCallback((payload) => {
    const { message, deadline, severity } = payload || {};
    if (message !== undefined) {
      setTimeout(() => {
        setMessages((state) => [...state, { id: new Date().getTime(), message, severity, deadline }]);
      }, 100);
    }
  }, []);

  const sendErrorMessage = React.useCallback(
    (payload) => {
      let message;
      if (typeof payload?.response?.data === 'string') {
        message = payload.response.data;
      } else if (typeof payload?.message === 'string') {
        message = payload.message;
      } else if (typeof payload === 'string') {
        message = payload;
      }
      sendMessage({ message, severity: 'error' });
    },
    [sendMessage],
  );

  const sendInfoMessage = React.useCallback(
    (message) => {
      sendMessage({ message, severity: 'info' });
    },
    [sendMessage],
  );

  const sendSuccessMessage = React.useCallback(
    (message) => {
      sendMessage({ message, severity: 'success' });
    },
    [sendMessage],
  );

  const sendWarningMessage = React.useCallback(
    (message) => {
      sendMessage({ message, severity: 'warning' });
    },
    [sendMessage],
  );

  const providerValue = React.useMemo(
    () => ({
      sendErrorMessage,
      sendInfoMessage,
      sendSuccessMessage,
      sendWarningMessage,
      sendMessage,
    }),
    [sendErrorMessage, sendInfoMessage, sendSuccessMessage, sendWarningMessage, sendMessage],
  );

  const handleClose = React.useCallback((id) => {
    setMessages((state) => state.filter((r) => r.id !== id));
  }, []);

  const handleClickAway = React.useCallback(() => {
    setMessages([]);
  }, []);

  return (
    <>
      <SnackContext.Provider value={providerValue}>{children}</SnackContext.Provider>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box className={classes.container}>
          {messages &&
            messages.map((item) => (
              <Alert key={item.id} id={item.id} severity={item.severity} message={item.message} onClose={handleClose} />
            ))}
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default SnackProvider;
