import React from 'react';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Breadcrumbs from 'components/Breadcrumbs';

import SubHeader from '../SubHeader';
import logo from '../../../../images/DHI_Logo.png';
import useStyles from './lib/useStyles';

const Header = ({ breadcrumbs, withSubheader, control }) => {
  const { push } = useHistory();
  const classes = useStyles();
  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.container}>
          <Box className={classes.block} justifyContent="flex-start">
            <Box
              paddingX={2}
              onClick={() => push('/')}
              display="flex"
              alignItems="center"
              // default left logo use 'row', to make the logo on the right side, use "row-reverse"
              flexDirection="row"
              justifyContent="center"
            >
              <img src={logo} alt="Mike Operations Web" style={{ height: '50px', padding: '0 10px' }} />
              <Typography variant="h5" style={{ whiteSpace: 'nowrap' }}>
                Mike Operations Web
              </Typography>
            </Box>
          </Box>
          <Box className={classes.block} justifyContent="center">
            <Breadcrumbs list={breadcrumbs} />
          </Box>
          <Box className={classes.block} justifyContent="flex-end" />
        </Box>
        <Box className={classes.block} justifyContent="flex-end">
          {control && control}
        </Box>
      </Toolbar>
      {withSubheader && <SubHeader />}
    </AppBar>
  );
};

export default Header;
