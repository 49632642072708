import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // .use(Backend)
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        Backend,
        resourcesToBackend((language, namespace) =>
          import(`../public/locales/${language}/${namespace}.${language}.json`),
        ),
      ],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.{{lng}}.json',
        },
      ],
    },
    fallbackLng: 'en',
    react: {
      useSuspense: true,
    },
  });

export default i18n;
