import useSWR, { mutate } from 'swr';
import axios from '../containers/hooks/useAxios';

export const getAutoConfigurationOptions = async (dbName: string, path: string, scenario: string) => {
  const response = await axios.get(`/api/configuration?system=${dbName}&simulationPath=${path}&scenario=${scenario}`);
  const { data } = response || {};
  return data;
};

export const createDynamicLayer = async (
  systemName: string,
  scenario: string,
  simulationPath: string,
  layers: [any],
  tsType: [string],
  quantity: [string],
  units: [string],
) => {
  const response = await axios.post(`/api/configuration/dynamic-layer?system=${systemName}`, {
    scenario,
    simulationPath,
    layers,
    tsType,
    quantity,
    units,
  });
  const { data } = response || {};
  return data;
};

export const getTitle = async () => {
  const response = await axios.get('/api/configuration/systems');
  const { data } = response || {};
  return data;
};

export const getVersion = async () => {
  const response = await axios.get('/api/configuration/product-version');
  const { data } = response || {};
  return data;
};

export const getSystems = async () => {
  const response = await axios.get('/api/configuration/systems');
  const { data } = response || {};
  return data;
};

export const createSystem = async (obj: any) => {
  const response = await axios.post('/api/configuration/system/create', obj);
  const { data } = response || {};
  return data;
};

export const updateSystem = async (oldname: string, obj: any) => {
  const response = await axios.put(`/api/configuration/system/update?oldname=${oldname}`, obj);
  const { data } = response || {};
  return data;
};

export const deleteSystem = async (system: string) => {
  const response = await axios.delete(`/api/configuration/system/delete?system=${system}`);
  const { data } = response || {};
  return data;
};

export const updateSystemOrder = async (systemIds: string[]) => {
  const response = await axios.put('/api/configuration/update-system-order', { systemIds });
  const { data } = response || {};
  return data;
};

export const modifyAppTitle = async (appTitle: string) => {
  const response = await axios.post(`/api/configuration/create-or-update?appTitle=${appTitle}`);
  const { data } = response || {};
  return data;
};
export const getDatabases = async (server: string, port: string, username: string, password: string) => {
  const response = await axios.post('/api/configuration/system/databases', {
    server,
    port,
    username,
    password,
  });
  const { data } = response || {};
  return data;
};

export const addStaticLayer = async (system: string, fullname: string) => {
  const response = await axios.post(
    `/api/configuration/static-layer?system=${system}&fullname=${fullname.split('/').join('|')}`,
  );
  const { data } = response || {};
  return data;
};

export const addStaticLayerStatus = async (system: string, fullname: string) => {
  const response = await axios.post(
    `/api/configuration/static-status-layer?system=${system}&fullname=${fullname.split('/').join('|')}`,
  );
  const { data } = response || {};
  return data;
};

export const removeStaticLayer = async (system: string, name: string) => {
  const response = await axios.delete(
    `/api/featurecollections/${system}-gis/Web Configuration|${system}|Static|${name}`,
  );
  const { data } = response || {};
  return data;
};

export const renameStaticLayer = async (system: string, oldName: string, newName: string) => {
  const response = await axios.put(
    `/api/configuration/rename-layer?system=${system}&fullName=Web Configuration|${system}|Static|${oldName}&newName=${newName}`,
  );
  const { data } = response || {};
  return data;
};

export const updateVisibility = async (system: string, featureClassMeta: any) => {
  const response = await axios.put(
    `/api/configuration/static-layer/update-visibility?system=${system}`,
    featureClassMeta,
  );
  const { data } = response || {};
  return data;
};
export const getStaticFeatureProperties = async (system: string) => {
  const response = await axios.get(`/api/configuration/featureclass-properties?system=${system}`);
  const { data } = response || {};
  return data;
};

export const updatePlace = async (system: string, scenario: string, oldPlaceId: string, place: any) => {
  const response = await axios.put(
    `api/configuration/update-place?system=${system}&scenario=${scenario}&oldPlaceId=${oldPlaceId}`,
    place,
  );
  const { data } = response || {};
  return data;
};

export const addPlace = async (system: string, scenario: string, place: any) => {
  const response = await axios.post(`api/configuration/add-place?system=${system}&scenario=${scenario}`, place);
  const { data } = response || {};
  return data;
};

export const deletePlace = async (system: string, scenario: string, indicatorType: string, places: any[]) => {
  const response = await axios.delete(`api/configuration/delete-places`, {
    data: places,
    params: { system, scenario, indicatorType },
  });
  const { data } = response || {};
  return data;
};

export const renameIndicator = async (
  system: string,
  scenario: string,
  indicatorOldName: string,
  indicatorNewName: string,
) => {
  const response = await axios.put(
    `api/configuration/rename-indicators?system=${system}&scenario=${scenario}&indicatorOldName=${indicatorOldName}&indicatorNewName=${indicatorNewName}`,
  );
  const { data } = response || {};
  return data;
};

export const removeIndicator = async (system: string, scenario: string, indicatorName: string) => {
  const response = await axios.put(
    `api/configuration/remove-indicators?system=${system}&scenario=${scenario}&indicatorName=${indicatorName}`,
  );
  const { data } = response || {};
  return data;
};

export const updateThresholds = async (
  system: string,
  scenario: string,
  indicatorType: string,
  noThresholdsToKeep: string,
) => {
  const response = await axios.put(
    `api/configuration/update-thresholds?system=${system}&scenario=${scenario}&indicatorType=${indicatorType}&noThresholdsToKeep=${noThresholdsToKeep}`,
  );
  const { data } = response || {};
  return data;
};

export const updateAggregationType = async (
  system: string,
  scenario: string,
  indicatorType: string,
  aggregationTypeName: string,
) => {
  const response = await axios.put(
    `api/configuration/update-aggregationType?system=${system}&scenario=${scenario}&indicatorType=${indicatorType}&aggregationTypeName=${aggregationTypeName}`,
  );
  const { data } = response || {};
  return data;
};

export const deleteIconGrouping = async (system: string, scenario: string, oldGroupingName: string) => {
  const response = await axios.delete(
    `api/configuration/delete-iconGrouping?system=${system}&scenario=${scenario}&oldGroupingName=${oldGroupingName}`,
  );
  const { data } = response || {};
  return data;
};

export const updateIconGrouping = async (
  system: string,
  scenario: string,
  oldGroupName: string,
  newGrouping: object,
) => {
  const response = await axios.put(
    `api/configuration/update-iconGrouping?system=${system}&scenario=${scenario}&oldGroupName=${oldGroupName}`,
    newGrouping,
  );
  const { data } = response || {};
  return data;
};

export const addUpdate2Dconfig = async (system: string, scenario: string, projectIdString: string) => {
  const response = await axios.put(
    `api/scenario/${system}/${scenario}/add-or-update-2Dconfig?projectIdString=${projectIdString}`,
  );
  const { data } = response || {};
  return data;
};

export const exportPlaces = async (
  system: string,
  scenarioId: string,
  indicatorType: string,
  columnNames: string[],
) => {
  const response = await axios.post(
    `/api/configuration/export-csv?system=${system}`,
    { scenarioId, indicatorType, columnNames },
    {
      responseType: 'blob',
    },
  );
  const { data } = response || {};
  return data;
};

export const importPlaces = async (system: string, scenarioId: string, indicatorType: string, file: any) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await axios.post(
    `/api/configuration/import-csv?system=${system}&scenario=${scenarioId}&indicatorType=${indicatorType}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  const { data } = response || {};
  return data;
};

export const uploadLogo = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post('/api/configuration/uploadlogo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  if (response.status === 200) {
    return true;
  }
  return false;
};

export const getApplicationLogo = async () => {
  const response = await axios.get('/api/configuration/app-logo');
  const { data } = response || {};
  return data;
};

export const deleteLogo = async () => {
  const response = await axios.delete('/api/configuration/deletelogo');
  const { status } = response || {};
  return status;
};

const fetcher = async (url) => {
  const response = await axios.get(url);
  return response.data;
};

export const useSystemQuantiles = (dbName: string) => {
  const { data } = useSWR(dbName ? `/api/configuration/${dbName}/Quantiles` : null, fetcher, {
    dedupingInterval: 10000,
    refreshWhenHidden: false,
    refreshWhenOffline: false,
  });

  return {
    data,
  };
};

export const updateSystemQuantiles = async (dbName: string, values: string) => {
  await axios.post(`/api/configuration/${dbName}/Quantiles?quantiles=${values}`);
  mutate(`/api/configuration/${dbName}/Quantiles`);
};
