import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dispatchTimeseriesReset } from './TimeseriesChart';
import { dispatchScenarioReset } from './Scenario';
import { dispatchMapContentReset } from './MapContent';
import { getSimulationLatest } from '../factories/ScenarioApi';
import { dispatchRESETLPPLACE } from './Profile';

interface SystemState {
  scenario?: String;
  dbName?: string;
  systemId: string;
}

interface ForecastTypeState {
  system?: SystemState;
  forecastType: any;
  longProfileAvailable: boolean;
  isLongProfileCalcPrepared: boolean;
}

const initialState = {
  system: undefined,
  forecastType: undefined,
} as ForecastTypeState;

export const sliceName = 'ForecastType';

export const dispatchForecastType = createAsyncThunk(
  `${sliceName}.dispatchForecastType`,
  async (payload: any, thunkApi) => {
    const { system, forecastType, ghm } = payload;
    const { dispatch } = thunkApi;
    dispatch(dispatchTimeseriesReset());
    dispatch(dispatchScenarioReset());
    dispatch(dispatchMapContentReset());
    dispatch(dispatchRESETLPPLACE());
    const res = await getSimulationLatest(system, forecastType, ghm);
    return { ...forecastType, ...res, latest: res };
  },
);

export const dispatchSystem = createAsyncThunk(`${sliceName}.dispatchSystem`, async (payload: any, thunkApi) => {
  const { dispatch } = thunkApi;
  dispatch(dispatchTimeseriesReset());
  dispatch(dispatchScenarioReset());
  dispatch(dispatchMapContentReset());
  dispatch(dispatchRESETLPPLACE());
  return payload;
});

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // DispatchSimulationTime
    dispatchSimulationTime: (state, { payload }) => {
      return { ...state, ...{ forecastType: { ...state.forecastType, ...payload } } };
    },
    // DispatchSystemClean
    dispatchSystemClean: () => {
      return { ...initialState };
    },
  },
  extraReducers: {
    [dispatchForecastType.pending.toString()]: (state) => {
      return { ...state, pending: true };
    },
    [dispatchForecastType.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, pending: false, forecastType: { ...payload } };
    },
    [dispatchSystem.fulfilled.toString()]: (state, { payload }) => {
      return { ...state, forecastType: undefined, system: payload };
    },
  },
});

export default slice.reducer;
export const { dispatchSimulationTime, dispatchSystemClean } = slice.actions;
export const selectSystem = (state) => state[sliceName].system;
export const selectForecastType = (state) => state[sliceName].forecastType;
export const selectLongProfileAvailable = (state) => state[sliceName].longProfileAvailable;
export const selectIsLongProfileCalcPrepared = (state) => state[sliceName].isLongProfileCalcPrepared;
