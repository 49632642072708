import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

import { PersistGate } from 'redux-persist/integration/react';
import ConfirmProvider from './components/Dialog/Confirm/ConfirmProvider';
import Routes from './routers';
import * as serviceWorker from './serviceWorker';
import { MikeTheme } from './theme/mike';
import { store, persistor } from './store';
import SnackProvider from './components/Snack/SnackProvider';
import axios from './containers/hooks/useAxios';

import './i18n';

const fetcher = (url) =>
  axios.get(url).then((r) => {
    return r.data;
  });

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={MikeTheme}>
        <SWRConfig
          value={{
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            fetcher,
          }}
        >
          <SnackProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ConfirmProvider>
                <Routes />
              </ConfirmProvider>
            </MuiPickersUtilsProvider>
          </SnackProvider>
        </SWRConfig>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
