import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  show: false,
  projectId: undefined,
  schemeId: undefined,
  datasetId: undefined,
  legend: { type: 'threshold', domain: [], range: [] },
  timeStamps: [],
  showDfs: false,
  selectActiveLayer: [],
  staticLayer: [],
  wmsLayer: [],
};
export const sliceName = 'MapItemsStore';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // SET_MAP_ITEMS
    dispatchSETMAPITEMS: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    // RESET_MAP_ITEMS
    dispatchRESETMAPITEMS: () => {
      return {
        ...initialState,
      };
    },
    // SHOW_IMAGE
    dispatchSHOWIMAGE: (state, { payload }) => {
      return {
        ...state,
        showDfs: payload.showDfs,
      };
    },
    // SELECT ACTIVE LAYER
    dispatchSELECTACTIVELAYER: (state, { payload }) => {
      return {
        ...state,
        selectActiveLayer: payload.selectActiveLayer,
      };
    },
    // SELECT STATIC LAYER
    dispatchSELECTSTATICLAYER: (state, { payload }) => {
      return {
        ...state,
        staticLayer: payload.staticLayer,
      };
    },
    // SELECT WMS LAYER
    dispatchWMSLAYER: (state, { payload }) => {
      return {
        ...state,
        wmsLayer: payload.wmsLayer,
      };
    },
  },
});

export default slice.reducer;
export const {
  dispatchSETMAPITEMS,
  dispatchRESETMAPITEMS,
  dispatchSHOWIMAGE,
  dispatchSELECTACTIVELAYER,
  dispatchWMSLAYER,
  dispatchSELECTSTATICLAYER,
} = slice.actions;
export const selectMapItemsStore = (state) => state[sliceName];
export const selectShow = (state) => state[sliceName].show;
export const selectShowDfs = (state) => state[sliceName].showDfs;
export const selectActiveLayer = (state) => state[sliceName].selectActiveLayer;
export const selectStaticLayer = (state) => state[sliceName].staticLayer;
export const selectWMSLayer = (state) => state[sliceName].wmsLayer;
