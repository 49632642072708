import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Header from './partials/Header';
import Content from './partials/Content';
import Login from './partials/Login';
import SubHeader from './partials/SubHeader';

function ErrorFallback({ error }) {
  const { message, stack } = error;
  return (
    <div role="alert" style={{ padding: 10 }}>
      <p>Sorry, something went wrong. Please send the following information to DHI.</p>
      <pre>{message}</pre>
      <pre>{stack}</pre>
    </div>
  );
}

const Layout = ({ children, breadcrumbs = [], mainAppBar = false, navBar = false, control }) => {
  const hideAllBars = mainAppBar === true && navBar === false;
  const topbarSize = React.useMemo(
    () => (mainAppBar && navBar ? { minHeight: '40px', maxHeight: '40px' } : { minHeight: '100px' }),
    [mainAppBar, navBar],
  );

  const withTopbar = useMemo(
    () =>
      !hideAllBars && (
        <>
          <Toolbar disableGutters style={topbarSize} />
          {!mainAppBar && <Header breadcrumbs={breadcrumbs} withSubheader={navBar} control={control} />}
          {mainAppBar && navBar && (
            <AppBar style={{ height: 40 }}>
              <SubHeader />
            </AppBar>
          )}
        </>
      ),

    [hideAllBars, topbarSize, mainAppBar, navBar, breadcrumbs, control],
  );

  return (
    <>
      {withTopbar}
      <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
    </>
  );
};

Layout.Content = Content;
Layout.Login = Login;

export default Layout;
