import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { createAsyncThunkHelper, createSelectorHelper, createAsyncReducerHelper } from './helper';

export const sliceName = 'Filters';

const initialState = {};
const keyByIndicators = 'byIndicators';
const keyHideIndicate = 'HideIndicate';
const keyHideCategory = 'HideCategory';
const keyObservation = 'observation';
const keyExceedance = 'exceedance';
const keyFilterKey = 'filterKey';

export const dispatchHide = createAsyncThunkHelper(`${sliceName}.dispatchHide`);
export const dispatchTOGGLEGROUPINGS = createAsyncThunkHelper(`${sliceName}.dispatchTOGGLEGROUPINGS`);
export const dispatchTOGGLEOBSERVATION = createAsyncThunkHelper(`${sliceName}.dispatchTOGGLEOBSERVATION`);
export const dispatchTOGGLEEXCEEDANCE = createAsyncThunkHelper(`${sliceName}.dispatchTOGGLEEXCEEDANCE`);
export const dispatchSETSEARCHKEYS = createAsyncThunkHelper(`${sliceName}.dispatchSETSEARCHKEYS`);

const slice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: {
    [dispatchSETSEARCHKEYS.fulfilled]: createAsyncReducerHelper((state, { payload }) => {
      state[keyFilterKey] = payload;
    }),
    [dispatchHide.fulfilled]: createAsyncReducerHelper((state, { payload }) => {
      const group = state.byIndicators ? keyHideIndicate : keyHideCategory;
      if (state[group] === undefined) {
        state[group] = [];
      }
      const array = state[group];
      const index = array.indexOf(payload);
      if (index === -1) {
        array.push(payload);
      } else {
        array.splice(index, 1);
      }
    }),
    [dispatchTOGGLEGROUPINGS.fulfilled]: createAsyncReducerHelper((state) => {
      if (state[keyByIndicators] === undefined) {
        state[keyByIndicators] = true;
      }
      state[keyByIndicators] = !state[keyByIndicators];
    }),
    [dispatchTOGGLEOBSERVATION.fulfilled]: createAsyncReducerHelper((state) => {
      state[keyObservation] = !state[keyObservation];
    }),
    [dispatchTOGGLEEXCEEDANCE.fulfilled]: createAsyncReducerHelper((state) => {
      state[keyExceedance] = !state[keyExceedance];
    }),
  },
});

export default slice.reducer;

export const selectFilterKey = createSelectorHelper(sliceName, (state) => {
  if (state) {
    if (state[keyFilterKey] !== undefined) {
      return state[keyFilterKey];
    }
  }
  return '';
});
export const selectByIndicators = createSelectorHelper(sliceName, (state) => {
  if (state) {
    if (state[keyByIndicators] !== undefined) {
      return state[keyByIndicators];
    }
  }
  return true;
});
export const selectObservation = createSelectorHelper(sliceName, (state) => {
  if (state) {
    if (state[keyObservation] !== undefined) {
      return state[keyObservation];
    }
  }
  return false;
});
export const selectExceedance = createSelectorHelper(sliceName, (state) => {
  if (state) {
    if (state[keyExceedance] !== undefined) {
      return state[keyExceedance];
    }
  }
  return false;
});
export const selectHide = createSelectorHelper(sliceName, (state) => {
  if (state) {
    const group = state[keyByIndicators] ? keyHideIndicate : keyHideCategory;
    if (state[group] !== undefined) {
      return state[group];
    }
  }
  return [];
});
export const selectObservationValue = createSelector(selectObservation, (o) => (o ? 'observation' : undefined));
export const selectExceedanceValue = createSelector(selectExceedance, (o) => (o ? 'worst threshold' : undefined));
