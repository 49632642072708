import React from 'react';
// import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const Content = ({ children, withPadding = true }) => {
  return (
    <Box width="inherit" height="inherit" py={withPadding ? '2rem' : ''}>
      {children}
    </Box>
  );
};

export default Content;
