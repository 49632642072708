import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import CoreBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import useStyles from './lib/useStyles';

const Breadcrumbs = ({ list, ...rest }) => {
  const classes = useStyles({});
  return (
    <CoreBreadcrumbs separator="›" {...rest}>
      {list.map(({ label, href, onClick, isActive = false }) => (
        <Link
          key={label}
          className={clsx(classes.link, {
            [classes.linkIsActive]: isActive,
          })}
          href={href}
          onClick={onClick}
        >
          <Typography
            variant="body2"
            className={clsx({
              [classes.labelIsActive]: isActive,
            })}
          >
            {label}
          </Typography>
        </Link>
      ))}
    </CoreBreadcrumbs>
  );
};

export default Breadcrumbs;
