import React, { lazy, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MOWebLayout from '../containers/MOWebLayout';
import Signout from '../containers/Accounts/Signout';

const Scenario = lazy(() => import('containers/Scenario'));
const Main = lazy(() => import('containers/Main'));
const GlobalHydrologicalModel = lazy(() => import('containers/GlobalHydrologicalModel'));
const Configuration = lazy(() => import('containers/Configuration'));
const Status = lazy(() => import('containers/Status'));
const Accounts = lazy(() => import('containers/Accounts'));
const CreateGroups = lazy(() => import('containers/CreateGroups'));

export const history = createBrowserHistory();

const DefaultLayout = ({ exact, path, component }) => {
  return (
    <MOWebLayout>
      <Route path={path} exact={exact} component={component} />
    </MOWebLayout>
  );
};

const Routes = () => {
  const redirect = (path) => {
    const { origin } = window.location;
    window.location.assign(`${origin}/${path}`);
  };

  return (
    <Router history={history}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <DefaultLayout path="/scenario" exact component={Scenario} />
          <DefaultLayout path="/configuration" exact component={Configuration} />
          <DefaultLayout path="/status" exact component={Status} />
          <DefaultLayout path="/" exact component={Main} />
          <DefaultLayout path="/main" component={Main} />
          <DefaultLayout path="/accounts" component={Accounts} />
          <DefaultLayout path="/creategroups" component={CreateGroups} />
          <Route path="/dashboard" render={() => redirect('dashboard')} />
          <Route path="/ghm" exact component={GlobalHydrologicalModel} />
          <Route path="/sign-out" exact component={Signout} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
