import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
// import { MikeNotificationContainer } from '@mike/mike-shared-frontend/mike-notification-list';
import { useTranslation } from 'react-i18next';

import {
  selectIsConfiguration,
  selectIsStatusboard,
  selectIsScenario,
  selectIsScenarioEdit,
  selectIsDashboard,
  selectIsDashboardEdit,
} from '../../../../reducers/Authorization';
// import { selectNotifications } from '../../../../reducers/Scenario';

const StyledTabs = withStyles({
  root: {
    minHeight: '40px',
    alignItems: 'center',
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    marginRight: '6px',
    minHeight: '28px',
  },
})(Tab);

const data = [
  { lable: 'Main view', link: '/main', default: true },
  {
    lable: 'Dashboards',
    link: '/dashboard',
    isDashboard: true,
    disabled: process.env.NODE_ENV !== 'production' || process.env.REACT_APP_DASHBOARD_DISABLED,
  },
  { lable: 'Status board', link: '/status', isStatusboard: true },
  { lable: 'Configuration', link: '/configuration', isConfiguration: true },
  {
    lable: 'Scenario',
    link: '/scenario',
    isScenario: true,
    disabled: process.env.REACT_APP_SCENARIO_DISABLED,
  },
];

const SubHeader = () => {
  const { pathname } = useLocation();
  const isStatusboard = useSelector(selectIsStatusboard, shallowEqual);
  const isConfiguration = useSelector(selectIsConfiguration, shallowEqual);
  const isScenario = useSelector(selectIsScenario, shallowEqual);
  const isScenarioEdit = useSelector(selectIsScenarioEdit, shallowEqual);
  const isDashboard = useSelector(selectIsDashboard, shallowEqual);
  const isDashboardEdit = useSelector(selectIsDashboardEdit, shallowEqual);
  const [value, setValue] = useState(0);
  const history = useHistory();
  // const scenariosStatus = useSelector(selectNotifications, shallowEqual);
  const { t } = useTranslation();
  // const notifications = React.useMemo(() => {
  //   if (scenariosStatus && scenariosStatus.length > 0) {
  //     return scenariosStatus.map(({ status, ...rest }) => ({
  //       ...rest,
  //       error: status === -1 || status === 'Failed',
  //     }));
  //   }
  //   return undefined;
  // }, [scenariosStatus]);
  React.useEffect(() => {
    const path = pathname.toLocaleLowerCase();
    data.forEach((item, index) => {
      if (((path === '' || path === '/') && item.default) || path.startsWith(item.link.toLocaleLowerCase())) {
        setValue(index);
      }
    });
  }, [pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box display="flex" height="40px" bgcolor="#DBE4E9" justifyContent="center">
      <Box display="flex" justifyContent="center">
        <StyledTabs
          value={value}
          textColor="primary"
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          aria-label="disabled tabs example"
        >
          {data.map((item, index) => (
            <StyledTab
              key={item.lable}
              label={t(`${item.lable}.data`)}
              to={item.link}
              disabled={
                item.disabled ||
                (item.isDashboard && !isDashboard && !isDashboardEdit) ||
                (item.isStatusboard && !isStatusboard) ||
                (item.isConfiguration && !isConfiguration) ||
                (item.isScenario && !isScenario && !isScenarioEdit)
              }
              onClick={() => {
                history.push(item.link);
              }}
              index={index}
            />
          ))}
        </StyledTabs>
        {/* {value === 4 && (
          <Box>
            <MikeNotificationContainer
              notifications={notifications}
              showDot={notifications && notifications.length > 0}
            />
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default SubHeader;
