import { createStyles, makeStyles } from '@material-ui/core/styles';

const inheritHeight = {
  minHeight: 'inherit',
  margin: 0,
  padding: 0,
};

export default makeStyles(() =>
  createStyles({
    toolbar: {
      minHeight: '60px !important',
      maxHeight: '60px !important',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: '-4px',
      overflow: 'hidden',
      ...inheritHeight,
    },
    block: {
      width: 'calc(100% / 3)',
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      ...inheritHeight,
    },
  }),
);
