import React, { useState, useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  message: {
    display: 'flex',
    margin: 5,
    alignItems: 'center',
  },
}));

const Alert = ({ id, message, severity, deadline, onClose }) => {
  const [snackOpen, setSnackOpen] = useState(false);
  const classes = useStyles();
  const handleClose = React.useCallback(() => {
    setSnackOpen(false);
    if (onClose) {
      onClose(id);
    }
  }, [id, onClose]);
  useEffect(() => {
    let tId;
    if (message) {
      setSnackOpen(true);
      if (deadline) {
        if (tId !== undefined) {
          clearTimeout(tId);
        }
        tId = setTimeout(() => {
          handleClose();
        }, deadline - new Date());
      }
    } else {
      handleClose();
    }
    return () => {
      if (tId !== undefined) {
        clearTimeout(tId);
      }
    };
  }, [deadline, handleClose, message]);
  const handleSnackClose = React.useCallback(
    (event, reason) => {
      if (reason === 'timeout' || reason === 'clickaway') {
        return;
      }
      handleClose();
    },
    [handleClose],
  );
  return (
    snackOpen && (
      <MuiAlert severity={severity} className={classes.message} onClose={handleSnackClose}>
        {message}
      </MuiAlert>
    )
  );
};

export default Alert;
