import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../containers/hooks/useAxios';

const initialState = {
  timeseries: [],
  timeseries2D: [],
  values: [],
  valuesStatistics: [],
  statisticMethod: '',
  statisticFrom: null,
  statisticTo: null,
  allowOtherSimulations: true,
  isFetchingPredictionIntervals: false,
};
export const sliceName = 'TimeseriesChart';

export const dispatchTimeseriesValue = createAsyncThunk(
  `${sliceName}.dispatchTimeseriesValue`,
  async ({
    dbName,
    path,
    simulationPath,
    simulationStart,
    simulationEnd,
    referenceType,
    timeOfForecast,
    indicator,
    aggregationType,
    name,
    isCompared,
  }) => {
    const ts = path.replace('[Path]', simulationPath.split('|').join('/')).split('|').join('||');
    let query = '';
    if (simulationStart && simulationEnd) {
      query = `?from=${simulationStart}&to=${simulationEnd}`;
    }
    const response = await axios.post(`api/${dbName}-timeseries/ts/list/values${query}`, [ts]);
    const { data } = response || {};
    const d = Object.values(data);
    return {
      values: d.length === 1 ? d[0] : [],
      simulationPath,
      name,
      indicatorName: indicator,
      timeOfForecast,
      simulationStart,
      simulationEnd,
      referenceType,
      isCompared,
      aggregationType,
    };
  },
);

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // DispatchTimeseriesReset
    dispatchTimeseriesReset: (state) => {
      return {
        ...initialState,
        statisticMethod: state.statisticMethod,
        statisticFrom: state.statisticFrom,
        statisticTo: state.statisticTo,
      };
    },
    // RESET_TIMESERIES_STATISTICS_VALUES:
    dispatchRESETTIMESERIESSTATISTICSVALUES: (state) => {
      return {
        ...state,
        valuesStatistics: [],
        statisticMethod: '',
        statisticFrom: null,
        statisticTo: null,
      };
    },
    // SetForecastPredictionFetch
    dispatchSetForecastPredictionFetch: (state, { payload }) => {
      return {
        ...state,
        isFetchingPredictionIntervals: payload,
      };
    },
    // DispatchTimeseriesAdd:
    dispatchTimeseriesAdd: (state, { payload }) => {
      if (payload?.length > 0) {
        const arr = payload.filter((p) => !state.timeseries.some((t) => p.name === t.name));
        state.timeseries = state.timeseries.concat(arr);
      } else if (payload?.name && !state.timeseries.some((t) => payload.name === t.name)) {
        state.timeseries = state.timeseries.concat([payload]);
      }
    },
    // DispatchTimeseriesRemove
    dispatchTimeseriesRemove: (state, { payload }) => {
      return {
        ...state,
        timeseries: state.timeseries.filter(({ name }) => name !== payload.name),
        values: state.values.filter(({ name }) => name !== payload.name),
      };
    },
    // DispatchThresHoldsAdd
    dispatchThresHoldsAdd: (state, { payload }) => {
      return {
        ...state,
        timeseries: state.timeseries.map((item) => ({
          ...item,
          thresholdPlot: payload.indexOf(item.name) > -1,
        })),
      };
    },
    // DispatchThresHoldsRemove
    dispatchThresHoldsRemove: (state, { payload }) => {
      return {
        ...state,
        timeseries: state.timeseries.map((x) => {
          if (payload.indexOf(x.name) > -1) {
            return { ...x, thresholdPlot: false };
          }
          return x;
        }),
      };
    },
    // SET_TIMESERIES_STATISTICS_VALUES
    dispatchSETTIMESERIESSTATISTICSVALUES: (state, { payload }) => {
      return {
        ...state,
        valuesStatistics: [...state.valuesStatistics, ...payload],
        isFetchingPredictionIntervals: false,
      };
    },
    // SWITCH_TIMESERIES_THRESHOLD
    dispatchSWITCHTIMESERIESTHRESHOLD: (state, { payload }) => {
      return {
        ...state,
        timeseries: state.timeseries.map(({ name, ...rest }) =>
          name === payload ? { name, ...rest, thresholdPlot: true } : { name, ...rest, thresholdPlot: false },
        ),
      };
    },
    // REMOVE_TIMESERIES_THRESHOLDS_ALL
    dispatchREMOVETIMESERIESTHRESHOLDSALL: (state) => {
      return {
        ...state,
        timeseries: state.timeseries.map((timeseriesStore) => ({ ...timeseriesStore, thresholdPlot: false })),
      };
    },
    // SET_TIMESERIES_STATISTICS_METHOD
    dispatchSETTIMESERIESSTATISTICSMETHOD: (state, { payload }) => {
      return { ...state, statisticMethod: payload };
    },
    // SET_TIMESERIES_STATISTICS_FROM
    dispatchSETTIMESERIESSTATISTICSFROM: (state, { payload }) => {
      return { ...state, statisticFrom: payload };
    },
    // SET_TIMESERIES_STATISTICS_TO
    dispatchSETTIMESERIESSTATISTICSTO: (state, { payload }) => {
      return { ...state, statisticTo: payload };
    },
    // SET_TIMESERIES_VALUES
    dispatchSETTIMESERIESVALUES: (state, { payload }) => {
      return { ...state, values: [...state.values, ...payload] };
    },
    // SET_TIMESERIES_2D
    dispatchSETTIMESERIES2D: (state, { payload }) => {
      return { ...state, timeseries2D: [...state.timeseries2D, ...payload] };
    },
  },
  extraReducers: {
    [dispatchTimeseriesValue.fulfilled]: (state, { payload }) => {
      if (payload) {
        if (state.timeseries.some(({ name }) => name === payload.name)) {
          if (state.values) {
            const index = state.values.findIndex(
              (r) => r.name === payload.name && r.simulationPath === payload.simulationPath,
            );
            if (index !== -1) {
              state.values.splice(index, 1);
            }
          }
          state.values = [...state.values, { ...payload, isCompared: payload.isCompared }];
        }
      }
    },
  },
});

export default slice.reducer;
export const {
  dispatchTimeseriesReset,
  dispatchRESETTIMESERIESSTATISTICSVALUES,
  dispatchSetForecastPredictionFetch,
  dispatchTimeseriesAdd,
  dispatchTimeseriesRemove,
  dispatchThresHoldsAdd,
  dispatchThresHoldsRemove,
  dispatchSETTIMESERIESSTATISTICSVALUES,
  dispatchSWITCHTIMESERIESTHRESHOLD,
  dispatchREMOVETIMESERIESTHRESHOLDSALL,
  dispatchSETTIMESERIESSTATISTICSMETHOD,
  dispatchSETTIMESERIESSTATISTICSFROM,
  dispatchSETTIMESERIESSTATISTICSTO,
  dispatchSETTIMESERIESVALUES,
  dispatchSETTIMESERIES2D,
} = slice.actions;
export const selectTimeseries = (state) => {
  if (Array.isArray(state[sliceName].timeseries)) {
    return state[sliceName].timeseries;
  }
  return [];
};
export const selectTimeseries2D = (state) => state[sliceName].timeseries2D;
export const selectValues = (state) => state[sliceName].values;
export const selectValuesStatistics = (state) => state[sliceName].valuesStatistics;
export const selectStatisticMethod = (state) => state[sliceName].statisticMethod;
export const selectStatisticFrom = (state) => state[sliceName].statisticFrom;
export const selectStatisticTo = (state) => state[sliceName].statisticTo;
export const selectAllowOtherSimulations = (state) => state[sliceName].allowOtherSimulations;
export const selectIsFetchingPredictionIntervals = (state) => state[sliceName].isFetchingPredictionIntervals;
