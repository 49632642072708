import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPortal: true,
  isGHMView: process.env.REACT_APP_VIEW === 'GHM',
  options: { filter: false, show: false },
  pending: true,
  error: undefined,
};
export const sliceName = 'WaterToolsPortal';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // WaterToolsPortal
    dispatchWaterToolsPortal: (state, { payload }) => {
      return {
        ...state,
        isPortal: true,
        options: payload,
        pending: false,
      };
    },
    // StandAloneApp
    dispatchStandAloneApp: (state) => {
      return {
        ...state,
        isPortal: false,
        pending: false,
      };
    },
  },
});

export default slice.reducer;
export const { dispatchWaterToolsPortal, dispatchStandAloneApp } = slice.actions;
export const selectIsPortal = (state) => state[sliceName].isPortal;
export const selectOptions = (state) => state[sliceName].options;
export const selectPending = (state) => state[sliceName].pending;
