import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dispatchLogout } from '../../reducers/Authorization';
import { resetApp } from '../../actions';

const Signout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(dispatchLogout());
    resetApp();
    history.push('/');
  }, [dispatch, history]);
  return <></>;
};
export default Signout;
