import { Feature, Geometry } from 'geojson';
import { createSlice } from '@reduxjs/toolkit';

interface PlaceProperies {
  name: string;
}

interface LPToolState {
  selection: boolean;
  features: Feature<Geometry, PlaceProperies>[];
  dataReady: boolean;
}
const initialState: LPToolState = { selection: false, features: [], dataReady: false };
export const sliceName = 'Profile';
const addPlaces = (state: LPToolState, update: Feature<Geometry, PlaceProperies>) => {
  const { selection, features } = state;
  const found = features.find(({ properties }) => properties.name === update.properties.name) || false;
  if (found) {
    return { selection, features } as LPToolState;
  }
  return { selection, features: [...features, update] } as LPToolState;
};

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // DispatchProfileSelection
    dispatchProfileSelection: (state) => {
      return { ...state, selection: !state.selection, features: state.selection ? [] : state.features };
    },
    // ADD_LP_PLACE
    dispatchADDLPPLACE: (state, { payload }) => {
      return addPlaces(state, payload.feature);
    },
    // REMOVE_LP_PLACE
    dispatchREMOVELPPLACE: (state, { payload }) => {
      return { ...state, features: state.features.filter(({ properties }) => properties.name !== payload) };
    },
    // TOGGLE_DATA_READY_LP_PLACE
    dispatchTOGGLEDATAREADYLPPLACE: (state, { payload }) => {
      return { ...state, dataReady: payload };
    },
    // RESET_LP_PLACE
    dispatchRESETLPPLACE: () => {
      return initialState;
    },
  },
});

export default slice.reducer;
export const {
  dispatchProfileSelection,
  dispatchADDLPPLACE,
  dispatchREMOVELPPLACE,
  dispatchTOGGLEDATAREADYLPPLACE,
  dispatchRESETLPPLACE,
} = slice.actions;
export const selectSelection = (state) => state[sliceName].selection;
export const selectFeatures = (state) => state[sliceName].features;
export const selectDataReady = (state) => state[sliceName].dataReady;
