import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import ActionTypes from '../constants/actionTypes';
import MapContent, { sliceName as MapContentName } from './MapContent';
import TimeseriesChart, { sliceName as TimeseriesChartName } from './TimeseriesChart';
import ForecastType, { sliceName as ForecastTypeName } from './ForecastType';
import Authorization, { sliceName as AuthorizationName } from './Authorization';
import Systems, { sliceName as SystemsName } from './Systems';
import WaterToolsPortal, { sliceName as WaterToolsPortalName } from './WaterToolsPortal';
import MapItemsStore, { sliceName as MapItemsStoreName } from './MapItemsStore';
import Configuration, { sliceName as ConfigurationName } from './Configuration';
import Filters, { sliceName as FiltersName } from './Filters';
import Profile, { sliceName as ProfileName } from './Profile';
import Scenario, { sliceName as ScenarioName } from './Scenario';
import MapView, { sliceName as MapViewName } from './MapView';
import Animation, { sliceName as AnimationName } from './Animation';

const persistConfig = {
  key: 'MikeOperations',
  storage,
  blacklist: [AuthorizationName, MapViewName],
  // whitelist: [FiltersName, ConfigurationName],
};

const allReducers = combineReducers({
  [MapContentName]: MapContent,
  [TimeseriesChartName]: TimeseriesChart,
  [ForecastTypeName]: ForecastType,
  [AuthorizationName]: Authorization,
  [SystemsName]: Systems,
  [WaterToolsPortalName]: WaterToolsPortal,
  [MapItemsStoreName]: MapItemsStore,
  [ConfigurationName]: Configuration,
  [FiltersName]: Filters,
  [ProfileName]: Profile,
  [ScenarioName]: Scenario,
  [MapViewName]: MapView,
  [AnimationName]: Animation,
});

const rootReducer = (state, action) => {
  let savedState = state;
  if (action.type === ActionTypes.RESET_APP) {
    const { [AuthorizationName]: a, [SystemsName]: s, [WaterToolsPortalName]: w, [FiltersName]: f } = state;
    savedState = { [AuthorizationName]: a, [SystemsName]: s, [WaterToolsPortalName]: w, [FiltersName]: f };
  }
  return allReducers(savedState, action);
};
export type RootState = ReturnType<typeof allReducers>;
const reducer = persistReducer(persistConfig, rootReducer);
export default reducer;
