import { createContext } from 'react';

export type SnackContextProps = {
  sendErrorMessage: (message: String) => void;
  sendInfoMessage: (message: String) => void;
  sendSuccessMessage: (message: String) => void;
  sendWarningMessage: (message: String) => void;
};

export default createContext<SnackContextProps>({
  sendErrorMessage: () => {},
  sendInfoMessage: () => {},
  sendSuccessMessage: () => {},
  sendWarningMessage: () => {},
});
